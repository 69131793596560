@import '/styles/media';
@import '/styles/variables';

.header {
  position: sticky;
  width: 100%;
  top: 0;

  background-color: transparentize(black, 0.4);
  backdrop-filter: blur(20px) saturate(180%);
  z-index: 3;
}

.wrapper {
  max-width: $max-page-width-sm;
  margin: 0 auto;
  padding: 16px;
  padding-bottom: 10px;
  width: 100%;

  flex-direction: column;
  display: grid;
  gap: 16px;

  &.fluid {
    max-width: unset;
  }
}

.top {
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
}

.bottom {
  position: relative;
  margin: 0 auto;

  & .links {
    list-style: none;
    display: flex;

    & .item a {
      display: block;
      padding: 10px 16px;
    }
  }
}

.mobile-header {
  transform: translateY(-1px);
  padding: 12px 16px;
  display: grid;
  gap: 12px;

  & .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    & .humbugger {
      padding: 0;
      font-size: 1.5rem;
    }

    svg {
      margin: unset;
    }
  }
}
