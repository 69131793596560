@use '/styles/variables' as *;

.wrapper {
  position: relative;
}

// згодом...
// .number {
// }

.dot {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: $g-primary;
  border-radius: 50%;
  transform: translate(50%, -50%);
}

@each $key, $value in $colors {
  .#{'' + $key} {
    background-color: lighten($value, 10%);

    @extend %filter-shadow-#{'' + $key};
  }
}
