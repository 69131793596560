@use '/styles/variables' as v;
@use '/styles/media';

.widget {
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;

  & .widget-button-wrapper button,
  & .widget-button-wrapper a {
    width: 100%;
    height: 100%;
    border-radius: 0;
    cursor: pointer;
    padding: map-get(v.$spaces, md);
    border: none;
    background-color: transparent;
  }

  & a {
    text-align: center;
    border-radius: 0;
  }

  &-buttons-container {
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid v.color(dividers);
    & > :not(:last-child) {
      border-right: 1px solid v.color(dividers);
    }
    :first-child button {
      border-end-start-radius: map-get(v.$border-radiuses, md);
    }
    :last-child button {
      border-end-end-radius: map-get(v.$border-radiuses, md);
    }
  }

  &-button-wrapper {
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

@each $key, $value in v.$colors {
  .#{'' + $key} {
    background-color: $value;
  }
}

@each $key, $value in v.$border-radiuses {
  .#{'' + $key} {
    border-radius: $value;
  }
}

@each $key, $value in v.$colors {
  .#{'bc' + $key} {
    border-color: $value;
    // main border
    &.glow {
      border-color: v.color(white);

      @extend %basic-shadow-#{'' + $key};
    }
  }
}
