@import '/styles/media';
@import '/styles/variables';

.header {
  &-wrapper {
    height: calc(var(--header-height) + var(--granted-secure-banner-height, 0));
    // need to set a explicit height for proper spacings in main layout
  }

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: map-get($spaces, lg);
  background-color: transparentize(color(black), 0.2);
  backdrop-filter: blur(10px);
  height: var(--header-height);
  position: sticky;
  top: var(--granted-secure-banner-height);
  z-index: 3;
  transition: background-color 0.3s;

  &-inner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    max-width: $max-page-width-sm;
    margin: 0 auto;

    &-with-max-width {
      max-width: none;
    }
  }

  @include media-breakpoint-down(lg) {
    &-wrapper.granted-secure {
      --granted-secure-banner-height: 35px;
    }

    background-color: color(elevated);
  }
}

.header-event {
  grid-area: header;
  background-color: rgb(0 0 0 / 100%);
}

.navigation {
  display: flex;
  align-items: center;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.links {
  display: flex;
  list-style: none;
  color: map-get($colors, g-white);
}

.item {
  position: relative;

  a {
    font-size: 15px;
    letter-spacing: 0.08em;
    line-height: 28px;
    text-decoration: none;
    padding: 0 12px;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sub-links {
    position: absolute;
    display: none;
  }

  &:hover > .sub-links {
    display: flex;
  }

  &:hover > .link svg {
    transition: 0.3s;
    transform: rotateZ(180deg);
  }
}
