@import '/styles/variables';
@import '/styles/media';

.layout {
  display: grid;
  // header is fixed so it does not have any area
  grid-template-rows: min-content auto;
  min-height: 100%;

  // Layout height = 100% of the screen
  height: -moz-available;
  height: -webkit-fill-available;
}
