@import '/styles/media';

.desktop {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
