@use '/styles/variables' as *;

.menu {
  display: grid;
  border-radius: 8px;
  padding: 0.5rem !important;
  border: 1px solid color(gray);
  background-color: color(elevated);
  font-size: 1rem;
  user-select: none;

  & .item {
    padding: 0.5em 1em;
    color: white;
    transition: 0.15s;
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 0.5em;
    }

    &:hover,
    &:active,
    &:focus {
      svg {
        @extend %filter-shadow-primary;
      }
      cursor: pointer;
      text-shadow: shadows(text, ($g-primary, $g-primary), (25px, 25px));
    }

    & > a::before {
      position: absolute;
      inset: 0 0 0 0;
      content: '';
    }

    &.disabled {
      pointer-events: none;
      color: color(light-gray);
      svg {
        color: color(light-gray);
      }
    }
  }
}
