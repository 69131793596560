@use '/styles/variables' as *;
@use '/styles/media' as *;
@use '/styles/utils' as u;

.footer {
  padding: 50px 16px;
  &-content {
    align-items: start;

    grid-template-areas:
      'company form fun'
      'company copyright fun';
    max-width: $max-page-width-sm;
    margin: 0 auto;

    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 512px 1fr;
    }

    @include media-breakpoint-down(xl) {
      grid-template-areas:
        'company form'
        'fun copyright';
    }

    @include media-breakpoint-down(lg) {
      grid-template-areas:
        'form'
        'company'
        'fun'
        'copyright';
    }
  }
}

.the-fun {
  grid-area: fun;

  justify-items: end;

  &-links {
    grid-auto-flow: row;
    gap: 8px;
    padding-right: 8px;

    a {
      display: grid;
      grid-auto-flow: column;
      justify-content: end;
      align-items: center;
      grid-gap: 8px;
    }
  }

  @include media-breakpoint-down(xl) {
    justify-items: start;

    &-links {
      grid-auto-flow: column;
      gap: 24px;
      padding-right: 0px;
      padding-left: 8px;

      a {
        span {
          display: none;
        }
      }
    }
  }
}

.the-company {
  grid-area: company;

  a {
    text-transform: uppercase;
  }

  span {
    text-transform: uppercase;
  }
}

.subscribe-form {
  grid-area: form;

  &-heading {
    justify-items: center;

    @include media-breakpoint-down(lg) {
      justify-items: start;
    }

    span {
      text-transform: uppercase;
      font-size: 1.5rem;
      text-align: center;

      @extend %text-shadow-purple;
    }
  }

  > div {
    border-radius: 0;
    padding: 24px 36px;
    display: grid;
    gap: 24px;
  }

  .subscribe {
    &-form {
      position: relative;
    }

    &-status {
      width: 100%;
      text-align: center;
      margin-top: 8px;
    }

    &-input {
      background-color: transparent;
      border: none;
      width: 100%;
      padding: 1rem;
      outline: none;
      font-weight: normal;
      border-radius: map-get($border-radiuses, md);
      border: 1px solid color(gray);
      color: color(white);
      caret-color: $g-primary-outline;
      transition: inherit;
      padding-right: 140px;
      font-size: map-get($font-sizes, sm);

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: color(white);
      }

      &:hover,
      &:focus,
      &:focus-within {
        border-color: $g-primary-outline;
        background-color: u.g-dimmest($g-primary);

        @extend %basic-shadow-primary;
      }
    }

    &-button {
      letter-spacing: $g-letter-spacing;
      font-size: map-get($font-sizes, body);
      line-height: map-get($line-heights, body);
      border-radius: map-get($border-radiuses, md);
      border: none;
      cursor: pointer;
      box-sizing: border-box;
      transition: 0.3s;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      user-select: none;
      position: absolute;
      top: 12px;
      right: 10px;
      width: auto;
      text-transform: uppercase;
      font-size: map-get($font-sizes, sm);

      border: none;
      background-color: transparent;
      color: white;

      &:hover {
        @extend %text-shadow-primary;

        transition: all 0.05s ease-in-out;
      }
    }
  }
}

.copyright {
  grid-area: copyright;

  display: grid;
  width: 100%;
  justify-items: center;

  @include media-breakpoint-down(xl) {
    justify-items: end;
  }

  @include media-breakpoint-down(lg) {
    justify-items: start;
  }
}
