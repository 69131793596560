@use '/styles/variables' as *;
@use '/styles/media' as *;
@use '/styles/utils' as u;

$primary-color: color(green);

.wrapper {
  position: relative;
}

.login-button {
  @include media-breakpoint-down(lg) {
    svg {
      display: none;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;

  & > *:nth-child(2) {
    margin-left: 8px;
  }

  & svg {
    stroke: $primary-color;
    color: $primary-color;
    will-change: filter;
    transition: filter 0.3s ease-in-out;
  }

  &:hover svg {
    @extend %filter-shadow-g-green-dark;
  }
}

.body {
  border-radius: 8px;
  border: 1px solid color(gray);
  position: absolute;
  right: 0;
  margin-top: 8px;
  padding: 0.5rem;
  width: 100%;
  max-width: 260px;
  min-width: 220px;
  background-color: color(black);
  display: flex;
  flex-direction: column;
  z-index: 100;
  transition: 0.3s transform, 0.3s opacity;
  overflow: hidden;

  opacity: 0;
  transform: translateY(-10px);

  &-opened {
    height: auto;
    transform: translateY(0);
    opacity: 1;
  }

  &-hidden {
    height: 0;
    min-width: 0;
    width: 0;
    padding: 0;
  }
}

.link {
  padding: 8px 16px;
  font-size: 15px;
  text-decoration: none;
  color: color(white);
  font-weight: normal;
  font-size: 15px;
  outline: none;
  border-radius: 4px;

  & a {
    display: grid;
    align-items: center;
    gap: 10px;
    grid-template-columns: auto 1fr;
  }

  &-danger {
    color: color(red);

    span {
      color: currentColor;
    }

    path {
      fill: currentColor;
    }
  }
}

.hr {
  height: 1px;
  border: none;
  background-color: rgb(44, 44, 44);
  margin: 6px 0;
}
