@import '/styles/variables';
@import '/styles/media';

.content-widget {
  background-color: map-get($colors, black);
  box-shadow: 0px 4px 2px 0px #00000040;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include media-breakpoint-down(md) {
    box-shadow: unset;
  }

  &-mobile-border-radius {
    @include media-breakpoint-down(md) {
      border-radius: 0;
    }
  }

  &-with-padding {
    padding: map-get($spaces, lg);
  }
}
