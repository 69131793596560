@use '/styles/variables' as *;

.wrapper {
  position: fixed;
  visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1030;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.3s background-color;
  display: flex;
}

.sidebar {
  transition: 0.3s transform;
  background-color: color(elevated);
  transform: translateX(-100%);
  height: 100%;
  width: 100%;
}

.link {
  a {
    padding: 8px 24px;
    color: color(white);
    transition: 0.3s;
    font-size: 15px;
    text-align: center;

    display: block;
    align-items: center;
  }
}

.content {
  padding: 16px;
  position: relative;
}

.open {
  background-color: rgba(0, 0, 0, 0.8);

  .sidebar {
    transform: translateX(0);
  }
}

.dropdown {
  display: flex;
  flex-direction: column;

  .title {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    border: none;
    outline: none;
    background-color: transparent;

    svg {
      transition: 0.3s linear;
      transform: rotateZ(0);
    }
  }

  .body {
    overflow: hidden;
    transition: max-height 0.25s;
    will-change: max-height;

    .content {
      padding: 0 map-get($spaces, xxl);
      margin: 0;
    }
  }

  &Open {
    .title > svg {
      transform: rotateZ(180deg);
    }
  }
}

.header {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 16px;

  & .close-button {
    font-size: 1.25rem;
  }
}
