@import '/styles/variables';

.wrapper {
  display: flex;

  &-center {
    justify-content: center;
    align-items: center;
  }

  &-background {
    background-color: rgb(0 0 0 / 85%);
  }

  &-absolute {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.loader {
  font-size: 10px;

  width: 4em;
  height: 4em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  border-top: 0.3em solid rgba(255, 255, 255, 0.2);
  border-right: 0.3em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.3em solid rgba(255, 255, 255, 0.2);
  border-left: 0.3em solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@each $key, $value in $colors {
  .#{'' + $key} {
      border-left: 0.3em solid $value;
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
