@import '/styles/media';
@import '/styles/variables';

.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.19), inset 0 0px 6px rgba(0, 0, 0, 0.23);

  &-with-sidebar {
    display: grid;
    grid-template-columns: 440px 1fr;

    @include media-breakpoint-down(xl) {
      grid-template-columns: 380px 1fr;
    }

    @include media-breakpoint-down(lg) {
      grid-template-rows: auto 1fr;
      grid-template-columns: 1fr;
      align-content: start;
    }
  }

  &-with-padding {
    padding: map-get($spaces, xxl);
  }
}

@each $key, $value in $colors {
  .#{'' + $key} {
    background-color: $value;
  }
}
