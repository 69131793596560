@import '/styles/variables';
@import '/styles/media';

.content {
  max-width: $max-page-width;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    padding: 0;
  }
}
