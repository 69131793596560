@use '/styles/variables' as *;

.item {
  display: flex;
  align-items: center;
  padding: 8px 16px;

  &:hover {
    background-color: transparentize(white, 0.9);
  }
}

.image {
  border-radius: 50%;
  margin-right: 16px;
  object-fit: cover;
}

.calendar {
  border: 1px solid map-get($colors, dividers);
  flex-direction: column;
  margin-right: 16px;
  border-radius: 8px;
  text-align: center;
  padding: 6px 12px;
  display: flex;

  & .month {
    text-transform: uppercase;
    margin-bottom: 4;
    font-size: 11px;
    line-height: 1;
  }

  & .day {
    font-size: 18px;
    line-height: 1;
  }
}

.description {
  display: flex;
  flex-direction: column;

  & .title {
    color: white;
  }

  & .subtitle {
    color: map-get($colors, light-gray);
    font-size: 13px;
  }
}
