@use '/styles/variables' as *;
@use '/styles/utils' as u;

$height: 40px;

.wrapper {
  position: relative;
  color-scheme: dark;
  height: $height;
  z-index: 2;

  &--focus {
    & .icon svg {
      fill: color(white);
    }

    & .input-wrapper {
      @extend %basic-shadow-g-green-dark;
      border-color: white;
    }
  }
}

.background {
  transition: 0.15s background-color;
}

.input-wrapper {
  background-color: color(black);
  border-radius: 24px;
  border: 1px solid color(light-gray);
  align-items: center;
  position: relative;
  display: flex;
  min-width: 320px;
}

.input {
  background-color: transparent;
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  height: $height;
  color: color(white);

  padding-right: 1.5rem;
  padding-left: 3rem;
  font-size: 1rem;

  &::placeholder {
    color: color(light-gray);
  }

  &::selection {
    color: color(black);
    background: color(neutral);
  }
}

.icon {
  justify-content: center;
  pointer-events: none;
  align-items: center;
  position: absolute;
  display: flex;
  height: 100%;
  width: 3rem;

  svg {
    fill: color(light-gray);
    transition: 0.15s;
  }
}

.overlay {
  border: 1px solid color(dividers);
  border-radius: 8px;
  background-color: color(g-gray-6);
  position: relative;
  width: 100%;
  margin-top: 8px;
  max-height: 50vh;
  overflow: auto;
  display: grid;
  gap: 24px;
}

.sections {
  color: white;

  & .section {
    padding: 16px 0;

    & > .title {
      margin: 0px;
      margin-bottom: 6px;
      padding: 0 16px;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.12em;
    }
  }

  & .section:not(:last-child) {
    border-bottom: 1px solid color(dividers);
  }
}
